function Home() {
  return (
    <div className="home">
      <header>
        <h1>Home</h1>
      </header>
    </div>
  );
}

export default Home;
